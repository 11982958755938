const aboutCards = {
    Consultancy: require('./consultancy.webp'),
    Customer: require('./customer.webp'),
    Experience: require('./experience.webp'),
    Generation: require('./generation.webp'),
    Management: require('./management.webp'),
    Solution: require('./solution.webp'),
    Training: require('./training.webp'),
  };
  
  export default aboutCards;